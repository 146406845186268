<template>
   <div class="modal-mailings col-span-4 p-12 sm:p-14">
      <div class="close" @click="closeModal">Fermer</div>

      <div class="form">
         <div class="form-item">
            <label for="">Individus : </label>
            <div class="individu" v-for="(individu, index) in individusSelected" :key="index">
               {{ individu.prenom }} {{ individu.nom }}
            </div>
         </div>
         <div class="form-item">
            <label for="">Demande</label>
            <select class="py-1 px-2 mb-1 border border-gray-300 w-full bg-white" id="option_value"
               name="option_value" size="1" v-model="selectedOption">
               <option value="1788">2024-05-13 | INGENIEUR ETUDES ET DEVELOPPEMENTS MICROSOFT [ ATEKKA / OLIVE ]
               </option>
               <option value="1717">2019-04-05 | APPLICATION SUPPORT ANALYST - PRIVATE EQUITY [ ARDIAN (AXA PRIVATE
                  EQUITY) / PFUNDSTEIN ]</option>
            </select>
         </div>
         <Button class="mt-8" @click-button="saveDemande">Enregistrer</Button>
         <ErrorNotice v-if="isError" class="mt-5" />
         <SuccessNotice v-if="isSuccess" class="mt-5" />
      </div>
   </div>
</template>

<script>
import Button from "@/components/profil/global/Button.vue";
import UserService from "@/services/user.service";
import ErrorNotice from "@/components/profil/global/ErrorNotice.vue";
import SuccessNotice from "@/components/profil/global/SuccessNotice.vue";

export default {
   name: "ModalMailings",
   components: {
      Button,
      ErrorNotice,
      SuccessNotice
   },
   props: {
      individusSelected: { required: true, type: Array },
   },
   data() {
      return {
         id: this.$route.params.id,
         defaultEmails: [],
         selectedOption: null,
         isError: false,
         isSuccess: false,
      };
   },
   mounted() {
   },
   methods: {
      saveDemande() {
         const individusId = this.individusSelected.map(individu => individu.id);
         const datas = {
            type_mailing_id: parseInt(0),
            option_id: parseInt(1),
            individusId: individusId,
         };

         UserService.postItem(parseInt(this.selectedOption), datas, 'mailings').then(
            (response) => {
               console.log('response', response);
               this.$emit('reload', true);
               this.isError = false
               this.isSuccess = true
            },
            (error) => {
               this.isSuccess = false
               this.isError = true
               this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
         );
         
      },
      closeModal() {
         this.$emit('close-mailing', true);
      },
   },
};

</script>

<style lang="scss" scoped>
.modal-mailings {
   overflow-y: auto;
   height: 100%;
   position: fixed;
   top: 40%;
   left: 50%;
   transform: translateX(-50%) translateY(-50%);
   width: 50vw;
   height: 50vh;
   background: #fff;
   border: 1px solid #ddd;
   z-index: 999;
   .close {
      position: absolute;
      cursor: pointer;
      top: 20px;
      right: 20px;
   }
   .item-contact{
      // border-top:1px solid #ffffff60;
      padding-top: 13px;
      margin-top: 13px;
      .date-infos{
         border-bottom:1px solid #ffffff2b;
         padding-bottom: 5px;
         margin-bottom: 5px;
         background: #ffffff15;
         padding: 5px;
      }
   }
}
</style>
