<template>
  <div id="table-profil">

    <div class="wrap-modal-search" :class="{ close: !showModalSearch, 'animate-out': animateOutModal }">
      <TableSearch @close-event="handleCloseModal" @search-event="searchEvent" />
    </div>

    <div class="wrap-table py-12 sm:py-14 px-6 sm:px-8"
      :class="{ fullwidth: !showModalSearch && !showModalInfos, 'float-right': showModalSearch, 'float-left': showModalInfos }">
      <div class="flex flex-col space-y-6 md:space-y-0 md:flex-row justify-between mb-5 items-end">
        <div class="mr-6">
          <h1 class="text-2xl font-semibold mb-1">Selecteur de Profils</h1>
          <h2 class="text-gray-600 ml-0.5"><span class="text-black font-semibold">{{this.serverItemsLength}}</span>
            Réponses trouvées</h2>
        </div>
        <div>
          <Button class="" @click-button="onClickModalMailing"
            :class="this.itemsSelectedMailing.length ? 'cursor-pointer' : 'opacity-20 cursor-default'">Mailing</Button>
        </div>
      </div>

      <input type="text" v-model="searchByName"
        class="search-by-name w-full border rounded-md bg-gray-50 border-gray-300" placeholder="Recherche par nom"
        @keyup.enter="triggerSearchByName">

      <button class="filter-btn mt-3 px-5 py-3 border rounded-full border-gray-400 mb-3"
        @click="this.showModalSearch = true, this.showModalInfos = false">Filtres</button>

      <EasyDataTable :headers="headers" :items="individus" border-cell v-model:server-options="serverOptions"
        v-model:items-selected="itemsSelectedMailing" :server-items-length="serverItemsLength" :loading="loading"
        @click-row="openModal" buttons-pagination @update-sort="updateSort" />
    </div>

    <div class="wrap-modal-infos" :class="{ close: !showModalInfos, 'animate-out': animateOutModal }">
      <TableInfos ref="tableInfos" @close-event="handleCloseModal" :userId="selectedUserId" />
    </div>


  </div>

  <Profil v-if="showModalProfil" :class="{ show: showModalProfil, 'animate-out': animateOutModal }" />

  <ModalMailings v-if="showModalMailing" @close-mailing="showModalMailing = false"
    :individusSelected="itemsSelectedMailing" />

</template>
 
<script>
import { ref } from "vue";
import UserService from "@/services/user.service";
import Profil from "@/components/profil/Profil.vue"; 
import TableSearch from "@/components/profils/TableSearch.vue"; 
import TableInfos from "@/components/profils/TableInfos.vue"; 
import ModalMailings from "@/components/profils/ModalMailings.vue";
import Button from "@/components/profil/global/Button.vue";

export default {
    name: "TableProfils",
    components: {
        Profil,
        TableSearch,
        TableInfos,
        ModalMailings,
        Button,
    },
    data() {
      return {
        nbResult: ref([]),
        individus: ref([]),
        selectedUserId: ref([]),
        itemsSelectedMailing: ref([]),
        typesEmail: ref([]),
        loading: ref(false),
        serverItemsLength: ref(0),
        searchByName: ref(""),
        showModalProfil: false, 
        showModalSearch: false, 
        showModalInfos: false, 
        animateOutModal: false, 
        headers: [
          { text: "Nom Prénom ( Poste ) ", value: "fullNamePoste", sortable: true },  
          { text: "BAC+", value: "formation", sortable: true },
          { text: " Promotion", value: "promotion", sortable: true },
          { text: "MàJ", value: "dateMiseAJour", sortable: true },
          { text: "Création", value: "dateCreation", sortable: true },
        ],
        serverOptions: ref({
          page: 1,
          rowsPerPage: 25,
          // sortBy: 'dateMiseAJour',
          // sortType: 'desc',
        }),      
        filterSearch: {
          email_valide: 1,
          independant: 1,
          include_null_independant: 0
        },
        timerClick: null,
        showModalMailing: false,
     };
   }, 
   computed: {},
   mounted() {
      if (this.$route.name === 'UserDetails') {
        this.showModalProfil = true
      }
      this.loadIndividus();
   },
   methods: {
    handleCloseModal() {
        this.showModalSearch = false;
        this.showModalInfos = false;
    },
     openModal(user) {
        // if double click
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
          this.showModalProfil = true
          this.$router.push({ name: 'UserDetails', params: { id: user.id } })
          setTimeout(() => {
            this.showModalInfos = false
          }, 1500);

        // if click simple
        } else {
          this.timer = setTimeout(() => {
            this.timer = null;
            this.showModalSearch = false
            this.showModalInfos = true
            this.selectedUserId = user.id;
          }, 300);
        }
      }, 
      searchEvent(data) {
        this.filterSearch = data;
        this.serverOptions.page = 1;
        this.loadIndividus();
      },
     updateSort(sortOptions) {
        
        console.log('sortOptions', sortOptions)
        const sortParams = {};
        const propertyMapping = {
          'fullNamePoste': 'prenom',
          'dateMiseAJour': 'profil.dateMiseAJour',
          'dateCreation': 'profil.dateCreation',
          'promotion': 'cursus.promotion',
          'formation': 'cursus.niveau'
        };

        const sortBy = sortOptions.sortBy;
        const sortType = sortOptions.sortType;
        const sortKey = propertyMapping[sortBy] || sortBy;
        sortParams[sortKey] = sortType;
        this.serverOptions.sortBy = sortKey;
        this.serverOptions.sortType = sortType;
        this.loadIndividus();
        
      },

      loadIndividus(byName) {

          this.loading = true;
          var queryString = '';

          
          if(byName){
            queryString = `&fullName=${this.searchByName}`;
          } else {
            if (this.filterSearch !== null) {
              if (this.filterSearch.age_mini && this.filterSearch.age_maxi) {
                queryString = `&age[]=${this.filterSearch.age_mini}&age[]=${this.filterSearch.age_maxi}&include_null_ages=${this.filterSearch.include_null_ages}`;
              }
              if (this.filterSearch.dateStartInput && this.filterSearch.dateEndInput) {
                queryString += `&dateMiseAJour[]=${this.filterSearch.dateStartInput}&dateMiseAJour[]=${this.filterSearch.dateEndInput}&include_null_dates=${this.filterSearch.include_null_dates}`;
              }
              if (this.filterSearch.poste) {
                queryString += `&poste=${this.filterSearch.poste}`;
              }
              if (this.filterSearch.domaines) {
                queryString += `&domaine=${this.filterSearch.domaines}`;
              }
              if (this.filterSearch.competences) {
                queryString += `&competences=${this.filterSearch.competences}`;
              }
              if (this.filterSearch.promotion_mini && this.filterSearch.promotion_maxi) {
                queryString += `&promotion[]=${this.filterSearch.promotion_mini}&promotion[]=${this.filterSearch.promotion_maxi}&include_null_promotion=${this.filterSearch.include_null_promotion}`;
              }
              if (this.filterSearch.email_valide) {
                queryString += `&isDefautEmail=${this.filterSearch.email_valide}`;
              }
              if (this.filterSearch.independant) {
                queryString += `&independant=${this.filterSearch.independant}&include_null_independant=${this.filterSearch.include_null_independant}`;
              }
              if (this.filterSearch.formation_mini && this.filterSearch.formation_maxi) {
                queryString += `&formation[]=${this.filterSearch.formation_mini}&formation[]=${this.filterSearch.formation_maxi}&include_null_formation=${this.filterSearch.include_null_formation}`;
              }
            }
          }

          // Ajouter les paramètres de tri à l'URL
          if (this.serverOptions.sortBy && this.serverOptions.sortType) {
            if (this.serverOptions.sortBy === 'fullNamePoste') {
              queryString += `&order[prenom]=${this.serverOptions.sortType}`;
              queryString += `&order[nom]=${this.serverOptions.sortType}`;
            } else {
              queryString += `&order[${this.serverOptions.sortBy}]=${this.serverOptions.sortType}`;
            }
          }

          UserService.getIndividusSearch(this.serverOptions.page, this.serverOptions.rowsPerPage, queryString).then(
            (response) => { 
              this.individus = response.data["hydra:member"].map(individu => {
                  const formattedDateCreation = this.formatDate(individu.dateCreation);
                  const formattedDateMiseAJour = this.formatDate(individu.dateMiseAJour);
                  const formattedDateDeNaissance = this.formatDate(individu.dateNaissance);
                  return {
                      ...individu,
                      dateCreation: formattedDateCreation !== 'Invalid Date' ? formattedDateCreation : '',
                      dateMiseAJour: formattedDateMiseAJour !== 'Invalid Date' ? formattedDateMiseAJour : '',
                      dateDeNaissance: formattedDateDeNaissance !== 'Invalid Date' ? formattedDateDeNaissance : '',
                      formation: individu.niveau,
                      promotion: individu.promotion,
                      defautEmail: individu.defautEmail,
                      domaines: individu.domaines,
                      fullNamePoste: individu.poste ? 
                      `${individu.prenom} ${individu.nom} (${individu.poste})` 
                      : `${individu.prenom} ${individu.nom}`
                  };
                }); 
            
            this.serverItemsLength = response.data["hydra:totalItems"];
            console.log('response.data', this.individus)
            this.loading = false;
            
              if (!this.$store.state.dataType.isDataLoaded) {
                this.$store.dispatch('dataType/fetchData');
              }

            },
            (error) => {
            this.content =
               (error.response &&
               error.response.data &&
               error.response.data.message) ||
               error.message ||
               error.toString();
            }
          );
      },
      formatDate(dateString) {
         const date = new Date(dateString);
         const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
         return date.toLocaleDateString('fr-FR', options);
     },
     onClickModalMailing() {
       if (this.itemsSelectedMailing.length) {
         console.log('itemsSelectedMailing', this.itemsSelectedMailing)
         this.showModalMailing = true
        }
     },
     triggerSearchByName() {
       this.serverOptions.page = 1;  // Remet à la première page
       this.loadIndividus(true);     // Lancer la recherche par nom
     },
      // saveState() {
      //    sessionStorage.setItem('tableProfilsState', JSON.stringify(this.serverOptions));
      // },
   },
   watch: {
     serverOptions: {
       deep: true,
       handler() {
         this.loadIndividus();
        //  this.saveState();
       }
     },
     $route(to, from) {
      if (from.name == 'UserDetails') {
        this.animateOutModal = true;
        setTimeout(() => {
          this.showModalProfil = false;
          this.animateOutModal = false;
        }, 600);
      }
    },
     searchByName: {
       deep: true,
       handler(newValue) {
         this.serverOptions.page = 1
         if (newValue.length <= 3) {
           setTimeout(() => {
             this.loadIndividus(true);
           }, 1000);
         } else {
           setTimeout(() => {
             this.loadIndividus(true);
           }, 300);
         }
       }
     },
   },
};
</script>
<style lang="scss" >
.search-by-name{
  background-image: url('~@/assets/icon-search.png');
  background-repeat:  no-repeat;
  background-position: 10px center;
  background-size: 20px;
  height: 50px;
  padding-left: 50px;
  outline: none!important
}
.filter-btn{
    background-image: url('~@/assets/icon-filter2.png');
    background-repeat: no-repeat;
    background-position: 20px center;
    background-size: 20px;
    padding-left: 50px
}
.wrap-modal-search{
  left: 0;
  width: 40%;
  height: 100vh;
  transform: translateX(0%);
  overflow-x: auto;
  padding-bottom: 80px;
  position: fixed;
  // transition-duration: 0.4s;
  &.close{
    transform: translateX(-100%);
  }
  .close{
      position:absolute;
      cursor: pointer;
      top: 9px;
      right: 0;
  }
}
.wrap-modal-infos{
  right: 0;
  width: 30%;
  height: 100vh;
  transform: translateX(0%);
  overflow-x: auto;
  padding-bottom: 80px;
  position: fixed;
  // transition-duration: 0.4s;
  background:red;
  &.close{
    transform: translateX(100%);
  }
  .close{
      position:absolute;
      cursor: pointer; 
  }
}
.wrap-table{
  // transition-duration: 0.4s;
  &.float-right{
    width: 60%;
  }
  &.float-left{
    width: 70%;
  }
  &.fullwidth{
    width:100%;
  }
}
 
#table-profil .vue3-easy-data-table{
  .easy-checkbox input[type="checkbox"]:checked+label::before, 
  .easy-checkbox input[type="checkbox"].partSelected+label::before,
  .buttons-pagination .item.button.active {
      background: #080c0a;
    }
    .buttons-pagination .item.button.active{
      border-color:#080c0a!important
    }
  .vue3-easy-data-table__header{
    th{
      border-right: 0;
      height: 45px;
      background: #f8f8f8;
    }
  }
  .vue3-easy-data-table__body {
    td {
      border-right: 0;
      user-select: none;
      height: 55px;
      &:first-child{
        border-bottom: 0!important;
      }
    }
  }
}
</style>