<template>
   <div class="modal-infos col-span-4 py-12 px-6 sm:py-14 sm:px-6 bg-gray-50 border-l border-gray-200">
      <div class="relative">
         <div class="close" @click="closeModal">
            <img class="h-6 w-6" :src="require(`@/assets/icon-closer.png`) " alt="logout">
         </div>

         <!-- <h2 class="text-4xl font-semibold mb-2">Informations</h2>
         <div class="cursus" v-if="userId">
            <div class="item-cursus" v-for="(cursus, index) in userId.cursusInfo" :key="index">
                {{ formationTypes[cursus.formationId] }} ({{ cursus.promotion }})
            </div>
         </div> -->

         <h2 class="text-2xl font-semibold mb-2">Contact</h2>
         <div class="contacts mt-7" v-if="dataContacts">
            <div class="item-contact text-sm mt-4 pt-3 border-t border-gray-200"
               v-for="(contact, index) in dataContacts" :key="index">
               <p class="date-infos text-sm">{{ contact.date_contact }} : {{ contactTypes[contact.type_contact_id] }}
                  ({{
                  interlocuteur[contact.interlocuteur_id] }})</p>
               <div class="commentaire">{{ contact.commentaire_contact }}</div>
            </div>
         </div>

      </div>
   </div>
</template>

<script>
import UserService from "@/services/user.service";
import { ref } from "vue";
export default {
   name: "TableInfos",
   components: {},
   props: {
      userId: { required: true, type: Object },
   },
   data() {
      return { 
         dataContacts: ref([]), 
         contactTypes: {
            0: "(non defini)",
            57: "AFCII Inscription",
            15: "Compte rendu d'activité",
            44: "CONTACT PAR MAIL",
            28: "CONTACTEL",
            25: "CONVOCATION",
            58: "Création de la fiche",
            47: "DEMANDEDISPO",
            24: "DEMANDEINFOS",
            10: "Déjeuner",
            5: "Déjeuner Point Mission",
            37: "EMAIL",
            63: "Emission de contact LinkedIn",
            2: "Entretien d'embauche",
            61: "Entretien Téléphonique",
            11: "Envoi d'un courrier",
            13: "Envoi d'un E-Mail",
            68: "Envoi d'un message de prospection par LinkedI",
            8: "Envoi d'une documentation",
            12: "Envoi de mes coordonnées",
            34: "ENVOICV",
            40: "FORUM",
            29: "HORSCIBLE",
            62: "Invitation sur le compte Twitter",
            54: "LINKEDIN",
            39: "MAILING",
            36: "MAILING DOT.NET",
            55: "MAILING FINANCE SDM",
            35: "MAILING J.I.",
            45: "MAILING-BAC+2A5",
            43: "MAILING-JAVA",
            21: "MAILINGCOLDFUSION",
            22: "MAILINGINDEP",
            17: "MAILINGMICROSOFT",
            51: "MAILINGORACLE",
            19: "MAILINGPOWERBUILDER",
            32: "MAILINGSTAFF",
            33: "MAILINGSTAGE",
            46: "MAILINGUCB",
            52: "MAILINGWEBSPHERE",
            49: "MAILTERADATA",
            6: "Message laissé",
            23: "MESSAGETEL",
            66: "Mise à jour automatique",
            48: "MONETIQUE",
            42: "Note",
            18: "PAGESTAFF",
            53: "PRESENTATION",
            7: "Présentation collaborateur",
            59: "Prise de Rendez-Vous",
            20: "RELANCE EMAIL",
            60: "RELANCE MESSAGE",
            38: "RELANCE TEL",
            16: "Remise cadeaux",
            1: "Rendez vous commercial",
            3: "Rendez-vous de suivi de mission collaborateur",
            27: "REPNEG",
            64: "Réception de contact linkedIn",
            4: "Réunion",
            26: "RV(AMA)",
            56: "RV(CDB)",
            41: "RV(LMA)",
            65: "SMS",
            31: "STAGEAPKALA",
            30: "STAGENAOS",
            14: "Téléphone Point Mission",
            67: "Un café",
            9: "Une Info",
            50: "VOEUX",
         },
         interlocuteur: {
            0: "(non defini)",
            867: "Alain MATHIEU",
            1334: "Laurent MAUPU",
            1345: "Caroline de BOTTON",
            5696: "Pierre de CHAULIAC",
            48507: "Octave LAURENTIN",
            50109: "Antoine CONDIZI",
            50141: "Maya ALESHKEVICH",
            51901: "Laure de MURAT",
         },
         formationTypes: {
            158: "",
            163: "3IL",
            249: "AGRO",
            18: "AUDENCIA",
            9: "BAC",
            8: "BAC + Formation/AP",
            194: "BAC+0",
            191: "BAC+1",
            167: "BAC+2",
            105: "BAC+3",
            106: "BAC+3 Informatique",
            96: "BAC+4",
            190: "BAC+5",
            192: "BAC+6",
            193: "BAC+7",
            200: "BAC+8",
            201: "BAC+9",
            11: "BTS",
            10: "BTS + Formation/AP",
            92: "BTS Electronique",
            12: "BTS Informatique",
            260: "CENTRALE Lille",
            122: "CENTRALE Lyon",
            256: "CENTRALE Marseille",
            159: "CENTRALE Nantes",
            137: "CENTRALE Paris",
            6: "CESI (4)",
            118: "CESTI",
            70: "CNAM (Ingénieur)",
            245: "DAEU",
            15: "DEA",
            220: "DEA Finances",
            16: "DEA Informatique",
            225: "DEA Maths",
            231: "DECF",
            19: "DESS",
            24: "DESS Finance",
            20: "DESS Informatique",
            130: "DESS Ingénierie Mathématique",
            5: "DEST (CNAM)",
            4: "DESTAUP",
            175: "DEUG",
            21: "DEUG A",
            22: "DEUG B",
            23: "DEUG Sciences éco",
            182: "DOCTORAT",
            178: "DOCTORAT Finance",
            25: "DOCTORAT Informatique",
            179: "DOCTORAT Mathématiques",
            187: "DPCT CNAM",
            87: "DU Concepteur en Informatique de Gestion",
            112: "DU Monétique et Courrier Electronique",
            107: "DU ",
            177: "DUT",
            28: "DUT Informatique",
            131: "DUT Réseaux et Télécom",
            29: "DUT Statistiques",
            89: "E3I",
            246: "E42",
            47: "ECAM",
            257: "ECAM",
            56: "ECE",
            13: "Ecole de commerce",
            30: "Ecole de Gestion (BAC+6)",
            90: "Ecole de gestion de l'INT",
            230: "EDHEC",
            103: "EERIE",
            34: "EFREI",
            196: "EI.CESI",
            247: "EIGSI",
            100: "EIGSI La Rochelle Ingénieur",
            35: "EISTI",
            140: "EIT",
            155: "EIVL",
            233: "EMBA",
            234: "EMBA DAUPHINE",
            227: "EMBA ESCP",
            226: "EMBA ESSEC",
            27: "EMBA HEC",
            214: "EMBA INSEAD",
            235: "EMBA MIT",
            239: "EMLV",
            195: "EN",
            36: "ENAC",
            37: "ENI Brest",
            199: "ENI Metz",
            213: "ENI Tarbes",
            128: "ENIC",
            198: "ENPC",
            202: "ENS",
            185: "ENSAE",
            240: "ENSAE PARISTECH",
            186: "ENSAI",
            253: "ENSAIT",
            123: "ENSAM",
            116: "ENSCL",
            170: "ENSCP",
            85: "ENSEA",
            104: "ENSEEIHT",
            38: "ENSEIRB",
            166: "ENSEM",
            217: "ENSGTI",
            91: "ENSI CAEN",
            258: "ENSI Mans",
            212: "ENSIAME",
            223: "ENSIBS",
            126: "ENSIC",
            153: "ENSICA",
            39: "ENSIEG",
            136: "ENSIETA",
            172: "ENSIGC",
            55: "ENSIIE",
            197: "ENSIIE",
            150: "ENSIL",
            134: "ENSIMAG",
            127: "ENSMM",
            102: "ENSPM",
            135: "ENSSAT",
            180: "ENSSAT",
            154: "ENSTA",
            143: "ENSTIB",
            41: "EPF",
            236: "EPFL",
            42: "EPITA",
            189: "EPITECH",
            162: "EPM",
            43: "EPSI",
            149: "ESAIGELEC",
            205: "ESC",
            241: "ESCEN",
            209: "ESCP",
            164: "ESEM",
            113: "ESEO",
            251: "ESG",
            110: "ESGI",
            46: "ESIAG",
            48: "ESIAL",
            181: "ESIAME",
            49: "ESIEA",
            50: "ESIEE",
            184: "ESIG",
            145: "ESIGEC",
            207: "ESIGED",
            51: "ESIGELEC",
            52: "ESIGETEL",
            161: "ESIL",
            133: "ESIL",
            156: "ESILV",
            148: "ESIM",
            53: "ESIP",
            232: "ESIPE",
            138: "ESISAR",
            14: "ESLSCA",
            54: "ESME-Sudria",
            244: "ESMSC",
            142: "ESO",
            139: "ESPEO",
            98: "ESSAIM",
            114: "ESSAIM",
            210: "ESSEC",
            121: "ESSI",
            125: "ESSTIN",
            132: "ESTACA",
            151: "ESTIA",
            222: "ESTIA",
            124: "ESTIT",
            101: "ESTP",
            95: "ETGL (Ingénieur)",
            115: "EUDIL",
            26: "EXECUTIVE MBA",
            3: "Formation A/P",
            93: "Formation inconnue",
            237: "GEM",
            2: "GRETA (Formation AP)",
            204: "HEC",
            215: "HEI",
            17: "HETIC",
            31: "IAE",
            206: "ICAM",
            250: "ICN",
            188: "IFIPS",
            219: "IFMA",
            146: "IFSIC",
            160: "IMAC",
            157: "IMERIR",
            40: "IMT Atlantique",
            69: "INGENIEUR ENSI",
            33: "INGENIEUR Informatique",
            254: "INGESUP",
            32: "Ingénieur",
            97: "Ingénieur d'affaires en Informatique",
            68: "Ingénieur EPSI",
            67: "Ingénieur ESIB",
            65: "INPG-ENSERG",
            86: "INPG-ENSHMG",
            64: "INSA",
            63: "INSA Lyon",
            216: "INSEEC",
            174: "INSIA",
            57: "INT",
            60: "IPSA",
            94: "IRESTE",
            208: "ISAT",
            211: "ISC",
            243: "ISEG",
            144: "ISEN",
            58: "ISEP",
            61: "ISERPA",
            203: "ISIA",
            1: "ISIMA",
            117: "ISITV",
            165: "ISIV",
            62: "ISMCM Ingénieur",
            129: "ISTASE",
            147: "ISTASE",
            99: "ISTY",
            252: "ITIN",
            108: "IUSPIM",
            7: "Jeunes Ingénieurs (SEMA)",
            255: "KEDGE",
            71: "LICENCE",
            72: "LICENCE Informatique",
            74: "MAITRISE",
            76: "MAITRISE AES",
            75: "MAITRISE Informatique",
            77: "MAITRISE Mathémathiques",
            78: "MAITRISE Science Eco",
            80: "MASTER",
            176: "MASTER Finance",
            81: "MASTER Informatique",
            183: "MASTER Mathématiques",
            218: "MASTER Spécialisé",
            221: "MBA",
            259: "MBA HEC",
            79: "MIAGE",
            84: "MIAGE (Niveau)",
            242: "MINES Albi",
            109: "MINES Alès",
            169: "MINES Douai",
            119: "MINES Nancy",
            66: "MINES Paris",
            120: "MINES Saint-Etienne",
            248: "NEOMA",
            224: "NOVANCIA",
            228: "POLYTECH",
            73: "POLYTECH Marseille",
            141: "POLYTECH Montpellier",
            229: "POLYTECH Nantes",
            238: "SKEMA",
            88: "Sup Galilée",
            152: "SUPAERO",
            44: "SUPELEC",
            45: "SUPINFO",
            59: "TELECOM Paris",
            82: "THESE",
            83: "THESE INFORMATIQUE",
            111: "UTBM",
            171: "UTC",
            173: "UTT",
            168: "X",
         },
      };
   },
   mounted() {
      
   },
   watch: {
      userId: {
         deep: true,
         handler(userId) {
            UserService.getItem(userId, 'contacts').then(
               (response) => {
                  this.dataContacts = response.data
               },
               (error) => { this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString(); }
            );  
         },
      },
   },
   methods: {
      closeModal() {
         this.$emit('close-event', true);
      },
   },
};
</script>

<style lang="scss" scoped>
.modal-infos {
   overflow-y: auto;
   height: 100%;

   .close {
      position: absolute;
      cursor: pointer;
      top: 5px;
      right: 0;
   }
   .item-contact{
      .date-infos{
         border-bottom:1px solid #ffffff2b;
         padding-bottom: 5px;
         margin-bottom: 5px;
         background: #ffffff15;
         padding: 5px;
      }
   }
}
</style>
