<template>
   <div class="custom-checkbox">
      <input class="w-5 h-5" :id="id" type="checkbox" :checked="checked" @change="updateCheckBox">
      <label :for="id"></label>
   </div>
</template>

<script>
export default {
   name: 'CheckboxCustom',
   props: {
      id: {
         type: String,
         required: true
      },
      checked: {  // Prop pour indiquer si la checkbox est cochée ou non
         type: Boolean,
         default: false
      }
   },
   methods: {
      updateCheckBox(event) {
         this.$emit('change-checkbox', event.target.checked); // Émet la nouvelle valeur quand la checkbox change
      }
   }
};
</script>
<style lang="scss">
.custom-checkbox {
   position: relative;
   width: 20px;
   height: 20px;
   input[type="checkbox"] {
      position: absolute;
      opacity: 0;
   }
   input[type="checkbox"]:checked+label:after {
      transform: translate(-50%, -50%) scale(1);
   }
   label {
      display: block;
      width: 100%;
      height: 100%;
      border: 2px solid #888;
      border-radius: 3px;
      background-color: #fff;
      cursor: pointer;
      position: absolute;
      margin-top: 0;
      &:after {
         content: "";
         display: block;
         width: 1.25rem;
         height: 1.25rem;
         border-radius: 3px;
         background-color: black;
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%) scale(0);
         transition: transform 0.2s ease;
      }
      &::before {
         content: '';
         background-image: url('~@/assets/icon-check.png');
         background-repeat: no-repeat;
         background-position: center;
         background-size: 14px;
         filter: invert(1);
         position: absolute;
         height: 1.25rem;
         width: 1.25rem;
         z-index: 2;
         top: -1px;
         left: -1px;
      }
   }
}
</style>