<template>
   <TableProfils />
</template>

<script>
import TableProfils from "@/components/profils/TableProfils.vue";

export default {
   name: "Profils",
   components: {
      TableProfils,
   },
   data() {
      return {
      }
   },

   methods: {
      
   },
   mounted() {
   
      
   }
};
</script>
