<template>
   <div class="modal-search col-span-4 py-12 px-6 sm:py-14 sm:px-6 bg-gray-50 border-r border-gray-200">
      <div class="relative">
         <h1 class="text-2xl font-semibold mb-2 ">Filtre avancé</h1>
         <form id="form-search" class="" @submit.prevent="searchIndividu">

            <!-- Search email valide -->
            <div class="mt-7 pt-4 border-t border-gray-200 flex items-center gap-5">
               <CheckboxCustom :checked="email_valide" @change-checkbox="email_valide = $event" id="email_valide" />
               <label for="email_valide" class=" text-sm mt-0">Adresse email valide </label>
            </div>


            <!-- Search age -->
            <div class="mt-4 pt-4 border-t border-gray-200">
               <div class="flex items-center gap-5">
                  <CheckboxCustom @change-checkbox="age_check = $event" id="age_check" />
                  <label for="age_check" class=" text-sm mt-0">Tranche d'age</label>
               </div>
               <div v-if="age_check" class="flex gap-3 items-center mt-4 w-full">
                  <div class="flex gap-3 items-center">
                     <p class=" text-xs">De</p>
                     <select id="age_mini" v-model="age_mini" name="age_mini"
                        class="style_par_defaut disabled-input border w-16 p-2 rounded-md bg-gray-100 border-gray-300"
                        size="1" width:="225">
                        <option value="16" selected="selected">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                        <option value="32">32</option>
                        <option value="33">33</option>
                        <option value="34">34</option>
                        <option value="35">35</option>
                        <option value="36">36</option>
                        <option value="37">37</option>
                        <option value="38">38</option>
                        <option value="39">39</option>
                        <option value="40">40</option>
                        <option value="41">41</option>
                        <option value="42">42</option>
                        <option value="43">43</option>
                        <option value="44">44</option>
                        <option value="45">45</option>
                        <option value="46">46</option>
                        <option value="47">47</option>
                        <option value="48">48</option>
                        <option value="49">49</option>
                        <option value="50">50</option>
                        <option value="51">51</option>
                        <option value="52">52</option>
                        <option value="53">53</option>
                        <option value="54">54</option>
                        <option value="55">55</option>
                        <option value="56">56</option>
                        <option value="57">57</option>
                        <option value="58">58</option>
                        <option value="59">59</option>
                        <option value="60">60</option>
                        <option value="61">61</option>
                        <option value="62">62</option>
                        <option value="63">63</option>
                        <option value="64">64</option>
                        <option value="65">65</option>
                        <option value="66">66</option>
                        <option value="67">67</option>
                        <option value="68">68</option>
                        <option value="69">69</option>
                        <option value="70">70</option>
                        <option value="71">71</option>
                        <option value="72">72</option>
                        <option value="73">73</option>
                        <option value="74">74</option>
                        <option value="75">75</option>
                        <option value="76">76</option>
                        <option value="77">77</option>
                     </select>
                     <p class=" text-xs">ans à</p>
                     <select id="age_maxi" v-model="age_maxi" name="age_maxi"
                        class="style_par_defaut disabled-input border w-16 p-2 rounded-md bg-gray-100 border-gray-300"
                        size="1" width:="225">
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26" selected="selected">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                        <option value="32">32</option>
                        <option value="33">33</option>
                        <option value="34">34</option>
                        <option value="35">35</option>
                        <option value="36">36</option>
                        <option value="37">37</option>
                        <option value="38">38</option>
                        <option value="39">39</option>
                        <option value="40">40</option>
                        <option value="41">41</option>
                        <option value="42">42</option>
                        <option value="43">43</option>
                        <option value="44">44</option>
                        <option value="45">45</option>
                        <option value="46">46</option>
                        <option value="47">47</option>
                        <option value="48">48</option>
                        <option value="49">49</option>
                        <option value="50">50</option>
                        <option value="51">51</option>
                        <option value="52">52</option>
                        <option value="53">53</option>
                        <option value="54">54</option>
                        <option value="55">55</option>
                        <option value="56">56</option>
                        <option value="57">57</option>
                        <option value="58">58</option>
                        <option value="59">59</option>
                        <option value="60">60</option>
                        <option value="61">61</option>
                        <option value="62">62</option>
                        <option value="63">63</option>
                        <option value="64">64</option>
                        <option value="65">65</option>
                        <option value="66">66</option>
                        <option value="67">67</option>
                        <option value="68">68</option>
                        <option value="69">69</option>
                        <option value="70">70</option>
                        <option value="71">71</option>
                        <option value="72">72</option>
                        <option value="73">73</option>
                        <option value="74">74</option>
                        <option value="75">75</option>
                        <option value="76">76</option>
                        <option value="77">77</option>
                     </select>
                     <p class=" text-xs">ans</p>
                  </div>
                  <div class="flex items-center w-40 ml-auto">
                     <input type="checkbox" id="include_null_ages" v-model="include_null_ages" class=" border  w-4 h-4">
                     <label for="include_null_ages" class="mt-0 text-xs ml-2">Inclure les non renseignés</label>
                  </div>
               </div>
            </div>

            <!-- Search MAJ -->
            <div class="mt-4 pt-4 border-t border-gray-200">
               <div class="flex items-center gap-5">
                  <CheckboxCustom @change-checkbox="maj_check = $event" id="maj_check" />
                  <label for="maj_check" class=" text-sm mt-0">Dernière MAJ</label>
               </div>
               <div v-if="maj_check" class="flex gap-3 items-center mt-5 w-full">
                  <p class=" text-xs">Entre le</p>
                  <datepicker placeholder="Date de début" v-model="dateStartInput"
                     @update:modelValue="startDateSelected" language="fr"></datepicker>
                  <p class=" text-xs">et le</p>
                  <datepicker placeholder="Date de fin" v-model="dateEndInput" @update:modelValue="endDateSelected"
                     language="fr"></datepicker>
                  <div class="flex items-center w-40 ml-auto">
                     <input type="checkbox" id="include_null_dates" v-model="include_null_dates" class="border w-4 h-4">
                     <label for="include_null_dates" class="mt-0 text-xs ml-2">Inclure les non renseignés</label>
                  </div>
               </div>
            </div>

            <!-- Search independant -->
            <div class="mt-4 pt-4 border-t border-gray-200">
               <div class="flex items-center gap-5">
                  <CheckboxCustom :checked="independant" id="independant" @change-checkbox="independant = $event" />
                  <label for="independant" class="text-sm mt-0">Rechercher uniquement les indépendants</label>
               </div>
               <div v-if="independant" class="flex items-center mt-5 w-40 ml-auto">
                  <input type="checkbox" id="include_null_independant" v-model="include_null_independant"
                     class="border w-4 h-4">
                  <label for="include_null_independant" class="text-xs ml-2 mt-0">Inclure les non
                     renseignés</label>
               </div>
            </div>

            <!-- Search poste -->
            <div class="mt-4 pt-4 border-t border-gray-200">
               <div class="flex items-center gap-5">
                  <CheckboxCustom @change-checkbox="poste_check = $event" id="poste_check" />
                  <label for="poste_check" class="text-sm mt-0">Poste</label>
               </div>
               <div v-if="poste_check" class="flex gap-5 mt-4">
                  <input type="text" id="poste" v-model="poste" placeholder="Recherche par poste"
                     class="text-sm border w-64 p-2 rounded-md bg-gray-100 border-gray-300">
               </div>
            </div>

            <!-- Search competences -->
            <div class="mt-4 pt-4 border-t border-gray-200">
               <div class="flex items-center gap-5">
                  <CheckboxCustom @change-checkbox="competences_check = $event" id="competences_check" />
                  <label for="competences_check" class="text-sm mt-0">Compétence</label>
               </div>
               <div v-if="competences_check" class="flex gap-5 mt-4">
                  <input type="text" id="poste" v-model="competences" placeholder="Recherche par compétence"
                     class="text-sm  w-64 p-2 border rounded-md bg-gray-100 border-gray-300">
               </div>
            </div>

            <!-- Search domaine -->
            <div class="mt-4 pt-4 border-t border-gray-200">
               <div class="flex items-center gap-5">
                  <CheckboxCustom @change-checkbox="domaine_check = $event" id="domaine_check" />
                  <label for="domaine_check" class="text-sm mt-0">Domaine</label>
               </div>
               <div v-if="domaine_check" class="flex gap-5 mt-4">
                  <input type="text" id="poste" v-model="domaines" placeholder="Recherche par domaine"
                     class="text-sm border w-64 p-2 rounded-md bg-gray-100 border-gray-300">
               </div>
            </div>

            <!-- Search promotion -->
            <div class="mt-4 pt-4 border-t border-gray-200">
               <div class="flex items-center gap-5">
                  <CheckboxCustom @change-checkbox="promotion_check = $event" id="promotion_check" />
                  <label for="promotion_check" class="text-sm mt-0">Promotions</label>
               </div>

               <div v-if="promotion_check" class="flex gap-3 items-center mt-4 w-full">
                  <div class="flex gap-3 items-center">
                     <p class=" text-xs">De</p>
                     <select id="promotion_mini" v-model="promotion_mini" name="promotion_mini"
                        class="style_par_defaut disabled-input border w-20 p-2 rounded-md bg-gray-100 border-gray-300"
                        size="1" width:="225">
                        <option value="1965" selected="selected">1965</option>
                        <option value="1966">1966</option>
                        <option value="1967">1967</option>
                        <option value="1968">1968</option>
                        <option value="1969">1969</option>
                        <option value="1970">1970</option>
                        <option value="1971">1971</option>
                        <option value="1972">1972</option>
                        <option value="1973">1973</option>
                        <option value="1974">1974</option>
                        <option value="1975">1975</option>
                        <option value="1976">1976</option>
                        <option value="1977">1977</option>
                        <option value="1978">1978</option>
                        <option value="1979">1979</option>
                        <option value="1980">1980</option>
                        <option value="1981">1981</option>
                        <option value="1982">1982</option>
                        <option value="1983">1983</option>
                        <option value="1984">1984</option>
                        <option value="1985">1985</option>
                        <option value="1986">1986</option>
                        <option value="1987">1987</option>
                        <option value="1988">1988</option>
                        <option value="1989">1989</option>
                        <option value="1990">1990</option>
                        <option value="1991">1991</option>
                        <option value="1992">1992</option>
                        <option value="1993">1993</option>
                        <option value="1994">1994</option>
                        <option value="1995">1995</option>
                        <option value="1996">1996</option>
                        <option value="1997">1997</option>
                        <option value="1998">1998</option>
                        <option value="1999">1999</option>
                        <option value="2000">2000</option>
                        <option value="2001">2001</option>
                        <option value="2002">2002</option>
                        <option value="2003">2003</option>
                        <option value="2004">2004</option>
                        <option value="2005">2005</option>
                        <option value="2006">2006</option>
                        <option value="2007">2007</option>
                        <option value="2008">2008</option>
                        <option value="2009">2009</option>
                        <option value="2010">2010</option>
                        <option value="2011">2011</option>
                        <option value="2012">2012</option>
                        <option value="2013">2013</option>
                        <option value="2014">2014</option>
                        <option value="2015">2015</option>
                        <option value="2016">2016</option>
                        <option value="2017">2017</option>
                        <option value="2018">2018</option>
                        <option value="2019">2019</option>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        <option value="2026">2026</option>
                     </select>
                     <p class=" text-xs">jusqu'à</p>
                     <select id="promotion_maxi" v-model="promotion_maxi" name="promotion_maxi"
                        class="style_par_defaut disabled-input border w-20 p-2 rounded-md bg-gray-100 border-gray-300"
                        size="1" width:="225">
                        <option value="1965" selected="selected">1965</option>
                        <option value="1966">1966</option>
                        <option value="1967">1967</option>
                        <option value="1968">1968</option>
                        <option value="1969">1969</option>
                        <option value="1970">1970</option>
                        <option value="1971">1971</option>
                        <option value="1972">1972</option>
                        <option value="1973">1973</option>
                        <option value="1974">1974</option>
                        <option value="1975">1975</option>
                        <option value="1976">1976</option>
                        <option value="1977">1977</option>
                        <option value="1978">1978</option>
                        <option value="1979">1979</option>
                        <option value="1980">1980</option>
                        <option value="1981">1981</option>
                        <option value="1982">1982</option>
                        <option value="1983">1983</option>
                        <option value="1984">1984</option>
                        <option value="1985">1985</option>
                        <option value="1986">1986</option>
                        <option value="1987">1987</option>
                        <option value="1988">1988</option>
                        <option value="1989">1989</option>
                        <option value="1990">1990</option>
                        <option value="1991">1991</option>
                        <option value="1992">1992</option>
                        <option value="1993">1993</option>
                        <option value="1994">1994</option>
                        <option value="1995">1995</option>
                        <option value="1996">1996</option>
                        <option value="1997">1997</option>
                        <option value="1998">1998</option>
                        <option value="1999">1999</option>
                        <option value="2000">2000</option>
                        <option value="2001">2001</option>
                        <option value="2002">2002</option>
                        <option value="2003">2003</option>
                        <option value="2004">2004</option>
                        <option value="2005">2005</option>
                        <option value="2006">2006</option>
                        <option value="2007">2007</option>
                        <option value="2008">2008</option>
                        <option value="2009">2009</option>
                        <option value="2010">2010</option>
                        <option value="2011">2011</option>
                        <option value="2012">2012</option>
                        <option value="2013">2013</option>
                        <option value="2014">2014</option>
                        <option value="2015">2015</option>
                        <option value="2016">2016</option>
                        <option value="2017">2017</option>
                        <option value="2018">2018</option>
                        <option value="2019">2019</option>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        <option value="2026">2026</option>
                     </select>
                  </div>
                  <div class="flex items-center w-40 ml-auto">
                     <input type="checkbox" id="include_null_promotion" v-model="include_null_promotion"
                        class=" border  w-4 h-4">
                     <label for="include_null_promotion" class="mt-0 text-xs ml-2">Inclure les non renseignés</label>
                  </div>
               </div>
            </div>

            <!-- Search formation -->
            <div class="mt-4 pt-4 border-t border-gray-200">
               <div class="flex items-center gap-5">
                  <CheckboxCustom @change-checkbox="formation_check = $event" id="formation_check" />
                  <label for="formation_check" class="text-sm mt-0">Formation</label>
               </div>

               <div v-if="formation_check" class="flex gap-3 items-center mt-4 w-full">
                  <div class="flex gap-3 items-center">
                     <p class="text-xs">Entre Bac+</p>
                     <select id="formation_mini" v-model="formation_mini" name="formation_mini"
                        class="style_par_defaut disabled-input border w-16 p-2 rounded-md bg-gray-100 border-gray-300"
                        size="1" width:="225">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                     </select>
                     <p class="text-xs">et Bac+</p>
                     <select id="formation_maxi" v-model="formation_maxi" name="formation_maxi"
                        class="style_par_defaut disabled-input border w-16 p-2 rounded-md bg-gray-100 border-gray-300"
                        size="1" width:="225">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                     </select>
                  </div>
                  <div class="flex items-center w-40 ml-auto">
                     <input type="checkbox" id="include_null_ages" v-model="include_null_formation"
                        class=" border  w-4 h-4">
                     <label for="include_null_formation" class="mt-0 text-xs ml-2">Inclure les non
                        renseignés</label>
                  </div>
               </div>
            </div>

            <div class="mt-4 pt-4 border-t border-gray-200 flex w-full">
               <button class="mt-2 inline-flex px-5 py-3 text-white bg-black rounded-md mb-3 ml-auto"
                  type="submit">Valider les critères</button>
            </div>
         </form>
         <div class="close" @click="closeModal">
            <img class="h-6 w-6" :src="require(`@/assets/icon-closer.png`)" alt="logout">
         </div>
      </div>
   </div>
</template>
<script>
import Datepicker from 'vuejs3-datepicker';
import CheckboxCustom from "@/components/profil/global/CheckboxCustom.vue";

export default {
   name: "TableSearch",
   components: { 
      Datepicker,
      CheckboxCustom
   },
   data() {
      return {
         email_valide: true,
         age_check: false,
         age_mini: 16,
         age_maxi: 58,
         include_null_ages: 0,
         dateStartInput: null,
         dateEndInput: null,
         include_null_dates: 0,
         poste_check: false,
         maj_check: false,
         poste: null,
         domaines: null,
         domaine_check: false,
         // independant_check: true,
         independant: true,
         include_null_independant: 0,
         competences_check: false,
         competences: null,
         formation_check: false,
         promotion_check: false,
         promotion_mini: 1965,
         promotion_maxi: 2000,
         include_null_promotion: 0,
         formation_mini: 1,
         formation_maxi: 8,
         include_null_formation: 0,
      };
   }, 
   computed: {},
   mounted() {
   },
   methods: {
      closeModal() {
         this.$emit('close-event', true);
      },
      searchIndividu() {
         var searchData = {};

         if (this.email_valide) {
            searchData.email_valide = this.email_valide === true ? 1 : 0;
         }
         if (this.age_check) {
            searchData.age_mini = this.age_mini;
            searchData.age_maxi = this.age_maxi;
            searchData.include_null_ages = this.include_null_ages === true ? 1 : 0;
         }
         if (this.maj_check) {
            searchData.dateStartInput = this.dateStartInput ? this.dateStartInput.toISOString().substring(0, 10) : null;
            searchData.dateEndInput = this.dateEndInput ? this.dateEndInput.toISOString().substring(0, 10) : null;
            searchData.include_null_dates = this.include_null_dates === true ? 1 : 0;
         }
         if (this.independant) {
            searchData.independant = this.independant === true ? 1 : 0;
            searchData.include_null_independant = this.include_null_independant === true ? 1 : 0;
         }
         if (this.poste_check) {
            searchData.poste = this.poste ? this.poste : null;
         }
         if (this.competences_check) {
            searchData.competences = this.competences ? this.competences : null;
         }
         if (this.domaine_check) { 
            searchData.domaines = this.domaines ? this.domaines : null;
         }
         if (this.promotion_check) {
            searchData.promotion_mini = this.promotion_mini;
            searchData.promotion_maxi = this.promotion_maxi;
            searchData.include_null_promotion = this.include_null_promotion === true ? 1 : 0;
         }
         if (this.formation_check) {
            searchData.formation_mini = this.formation_mini;
            searchData.formation_maxi = this.formation_maxi;
            searchData.include_null_formation = this.include_null_formation === true ? 1 : 0;
         }

         this.$emit('search-event', searchData);
      },
      startDateSelected(payload) {
         this.dateStartInput = payload;
      },
      endDateSelected(payload) {
         this.dateEndInput = payload;
      },
   }
};
</script>
<style lang="scss">
.modal-search{
   overflow-y: auto;
   height:100%;
   .vuejs3-datepicker__value{
      min-width: 110px!important
   }
   .close {
      top: 10px;
      right: 0;
   }
}
</style>